import React,{useRef , useState,useEffect} from 'react'
import backgroundimg from '../images/new background-3.png'
import Topnavigationbar from '../components/Topnavigationbar'
import Footer from '../components/Footer'
import viveka_ratton from '../images/show rooms/viveka ratton.jpeg'
import window_fashions from '../images/show rooms/window fashions.jpeg'
import sakura_bookshop from '../images/show rooms/Sakura-BK_1.jpg'
import ravi_tailors from '../images/show rooms/Ravi Tailors.jpeg'
import agahas_publishers from '../images/show rooms/agahas publishers.jpeg'
import Bubble_dry from '../images/show rooms/Bubble Dry.jpeg'
import Ceylon_music from '../images/show rooms/Ceylon music centre.jpeg'
import Charika_learners from '../images/show rooms/Charika Learners.jpeg'
import Charm_tailors from '../images/show rooms/Charm tailors.jpeg'
import Excel_Net_Cargo from '../images/show rooms/Excel Net Cargo.jpeg'
import Noodeen from '../images/show rooms/Noodeen.jpeg'
import P_S from '../images/show rooms/P&S.jpeg'
import Salon_Bandara from '../images/show rooms/Salon Bandara.jpeg'
import Suzuki_genuine_parts from '../images/show rooms/Suzuki genuine parts.jpeg'
import LMR from '../images/show rooms/LMR.jpeg'
import DCT_Computers from '../images/show rooms/D.C.T.Computers.jpeg'
import Kids from '../images/show rooms/Kids.jpeg'
import stef_lanka from '../images/show rooms/stef lanka.mp4'
import jayasinghe_opticians from '../images/show rooms/Jayasinghe_opticians.jpg'
import Colombo_real_estate from '../images/show rooms/Colombo_real_estate.jpg'
import Photocraft_Studio from '../images/show rooms/Photocraft Studio.jpg'
import New_Dehara_Trading_Company from '../images/show rooms/New Dehara Trading Company.jpg'
import Sannora_Mosquito_Nets from '../images/show rooms/Sannora Mosquito Nets.jpg'
import ComputeX from '../images/show rooms/ComputeX.jpg'
import Ocean_Tour from '../images/show rooms/Ocean Tour.jpg'
import Nik_juice from '../images/show rooms/Nik Juice.jpg'
import Luckeege_Photo from '../images/show rooms/Luckeege Photo.jpg'
import Thushi_Fashion from '../images/show rooms/Thushi Fashion.jpg'
import Living_More_Home_Nursing from '../images/show rooms/Living More Home Nursing.jpg'
import Nuga_Best from '../images/show rooms/Nuga Best.jpg'
import { complex, motion,useAnimation } from 'framer-motion'
import { useInView } from 'framer-motion'

function Home() {
  const scrollRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    }
  };


  return (
    <div>
        <Topnavigationbar/>
        <div className="bg-gray-100 min-h-screen">
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="relative"
      >
        <img
          src={backgroundimg}
          alt="Background"
          className="w-full h-screen object-cover opacity-80"
          style={{ minHeight: '100vh' }}
        />
        {/* Animate the image overlay */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 1 }}
          className="absolute inset-0 bg-black"
        ></motion.div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
          <div className="px-6">
            <h2 className="text-4xl font-bold mb-2">Welcome to Welikada Plaza</h2>
            <p className="text-lg mb-4">
              Welikada Plaza is your ultimate shopping destination located in the heart of Rajagiriya. Explore a variety of stores and enjoy a unique shopping experience.
            </p>
            <a
              href="#"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Read More
            </a>
          </div>
        </div>
      </motion.div>
      </div>
      
    
      
      <div className="max-w-5xl mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg p-8">
        <div className="text-center md:text-left">
          <ul className="text-xl text-gray-700"> {/* Increased text size to text-xl */}
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="mb-4 flex items-start py-2 border-l-4 border-green-500 pl-2"
            >
              <svg className="h-6 w-6 mt-1 mr-3 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="font-semibold">Now available for shop rent (contact shop owner)</span>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="mb-4 flex items-start py-2 border-l-4 border-green-500 pl-2"
            >
              <svg className="h-6 w-6 mt-1 mr-3 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="font-semibold">24 hours security</span>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="mb-4 flex items-start py-2 border-l-4 border-green-500 pl-2"
            >
              <svg className="h-6 w-6 mt-1 mr-3 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="font-semibold">Parking available</span>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="flex items-start py-2 border-l-4 border-green-500 pl-2"
            >
              <svg className="h-6 w-6 mt-1 mr-3 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="font-semibold">Free commercial add</span>
            </motion.li>
          </ul>
        </div>
      </div>
    </div>

    
      

      {/* Featured Showrooms Section with Horizontal Scroll */}
      <div className="bg-gray-800 py-12">
        <div className="max-w-7xl mx-auto px-4 overflow-hidden relative">
          <h2 className="text-4xl font-semibold text-white mb-8 text-center">FEATURED SHOWROOMS</h2>
          <div className="flex items-center relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <button onClick={scrollLeft} className="text-black bg-white p-3 rounded-full focus:outline-none absolute left-0 top-1/2 transform -translate-y-1/2 shadow-md">
              &lt;
            </button>
            <div ref={scrollRef} className="flex space-x-8 overflow-hidden ml-auto">
              {/* Viveka Ratton Showroom */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <a href="http://www.viveca-rattan.com/" target="_blank" rel="noopener noreferrer">
                    <img src={viveka_ratton} alt="Viveka Ratton Showroom" className="w-full h-64 object-cover" />
                  </a>
                </motion.div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Viveka Ratton</h3>
                  <p className="text-gray-600">Luxury furniture for your home.</p>
                </div>
              </div>

              {/* Window Fashions Showroom */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <a href="https://www.windowfashions.lk" target="_blank" rel="noopener noreferrer">
                    <img src={window_fashions} alt="Viveka Ratton Showroom" className="w-full h-64 object-cover" />
                  </a>
                </motion.div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Window Fashions</h3>
                  <p className="text-gray-600">Stage Curtain supply.</p>
                </div>
              </div>
              

              {/* Ravi Tailers */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={ravi_tailors} alt="Sakura Bookshop" className="w-full h-64 object-cover" /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Ravi Tailers</h3>
                  <p className="text-gray-600">Custom tailoring and alterations.</p>
                </div>
              </div>

              {/* Sakura Bookshop */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={sakura_bookshop} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Sakura Bookshop</h3>
                  <p className="text-gray-600">Books, stationery, and more.
                  </p>
                </div>
              </div>

              {/* Agahas Prakashakayo */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href="https://www.windowfashions.lk"><img src={agahas_publishers} alt="Sakura Bookshop" className="w-full h-64 object-cover" /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Agahas Publishers</h3>
                  <p className="text-gray-600">Publishing books and journals.</p>
                </div>
              </div>

              {/* Bubble Dry */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Bubble_dry} alt="Sakura Bookshop" className="w-full h-64 " style={{ maxHeight: '100%', width: '100%',}}/></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Bubble Dry</h3>
                  <p className="text-gray-600">Luxury dry cleaning and laundry services.</p>
                </div>
              </div>

              {/* Ceylon Music  */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Ceylon_music} alt="Sakura Bookshop" className="w-full h-64 object-cover" /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Ceylon Music centre</h3>
                  <p className="text-gray-600">Musical instruments and accessories.</p>
                </div>
              </div>

              {/* Charika Learners */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Charika_learners} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Charika Learners</h3>
                  <p className="text-gray-600">Teaching Practical driving skills 
                  </p>
                </div>
              </div>

              {/* Charm Tailors */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Charm_tailors} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Charm Tailors</h3>
                  <p className="text-gray-600">Custom tailoring and alterations.</p>
                </div>
              </div>

              {/* Excel Net Cargo */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Excel_Net_Cargo} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Excel Net Cargo</h3>
                  <p className="text-gray-600">Freight forwarding and logistics services.</p>
                </div>
              </div>

              {/* Noodeen */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Noodeen} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">I.L.M Noodeen Hadjiar & Co</h3>
                  <p className="text-gray-600">##</p>
                </div>
              </div>

              {/* P&S */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={P_S} alt="Sakura Bookshop" className="w-full h-64 object-cover" /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Perera & Sons</h3>
                  <p className="text-gray-600">Food Store</p>
                </div>
              </div>

              {/* Salon Bandara */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Salon_Bandara} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Salon Bandara</h3>
                  <p className="text-gray-600">A distinguished provider of high-quality salon services and beauty products.</p>
                </div>
              </div>

              {/* Suzuki Genuine Parts */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Suzuki_genuine_parts} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Suzuki Genuine Parts</h3>
                  <p className="text-gray-600">Authentic parts and accessories for Suzuki vehicles.</p>
                </div>
              </div>

              {/* Stef Lanka */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <a href="https://steflanka.com" target="_blank" rel="noopener noreferrer">
                  <video className="w-full h-64 object-cover" autoPlay muted loop>
                    <source src={stef_lanka} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  </a>
                </motion.div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Stef Lanka</h3>
                  <p className="text-gray-600">All about travelling in Sri Lanka.</p>
                </div>
              </div>

              {/* LMR */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={LMR} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">LMR INTERNATIONAL PVT LTD</h3>
                  <p className="text-gray-600">Importers and Indenting Agents for Mining Machinery,Accessories, and Equipment</p>
                </div>
              </div>

              {/* Cute stationary for kids */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Kids} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Cute stationary for kids</h3>
                  <p className="text-gray-600">Explore our delightful collection of cute stationary designed especially for kids.</p>
                </div>
              </div>

              {/* D.C.T Computers */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={DCT_Computers} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">D.C.T Computers</h3>
                  <p className="text-gray-600">Your trusted source for all things computing.</p>
                </div>
              </div>

              {/* M.A JAYASINGHE OPTICIANS */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <a href="https://majayasingheopticians.lk" target="_blank" rel="noopener noreferrer">
                    <img src={jayasinghe_opticians} alt="Viveka Ratton Showroom" className="w-full h-64 object-cover" />
                  </a>
                </motion.div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">M.A Jayasinghe Opticians</h3>
                  <p className="text-gray-600">Expert eye care services and high-quality optical products.</p>
                </div>
              </div>

              {/* Colombo Real Estate */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <a href="https://www.colomborealestate.com" target="_blank" rel="noopener noreferrer">
                    <img src={Colombo_real_estate} alt="Viveka Ratton Showroom" className="w-full h-64 object-cover" />
                  </a>
                </motion.div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Colombo Real Estate</h3>
                  <p className="text-gray-600">Your trusted partner for buying, selling, and renting properties in Colombo.</p>
                </div>
              </div>

              {/* Photocraft Studio */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Photocraft_Studio} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Photocraft Studio</h3>
                  <p className="text-gray-600">Capturing life's most precious moments with professional photography services. 
                  </p>
                </div>
              </div>

              {/* New Dehara Trading Company */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={New_Dehara_Trading_Company} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">New Dehara Trading Company</h3>
                  <p className="text-gray-600">Exporting quality products globally with reliable and efficient services. 
                  </p>
                </div>
              </div>

              {/* Sannora Mosquito Nets */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Sannora_Mosquito_Nets} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Sannora Mosquito Nets</h3>
                  <p className="text-gray-600">Providing high-quality mosquito nets for protection and comfort.
                  </p>
                </div>
              </div>

              {/* ComputeX */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={ComputeX} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">ComputeX</h3>
                  <p className="text-gray-600">Offering innovative IT solutions and services for businesses and individuals.
                  </p>
                </div>
              </div>

              {/* Ocean Tour */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Ocean_Tour} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Ocean Tour</h3>
                  <p className="text-gray-600">Specializing in unforgettable travel experiences and oceanic adventures.
                  </p>
                </div>
              </div>

              {/* Nik juice */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Nik_juice} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Nik Juice</h3>
                  <p className="text-gray-600">Offering refreshing and natural fruit juices for a healthy lifestyle.
                  </p>
                </div>
              </div>

              {/* Luckeege Photo */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Luckeege_Photo} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Luckeege Photo</h3>
                  <p className="text-gray-600">Capturing beautiful moments with professional photography services.
                  </p>
                </div>
              </div>

              {/* Thushi Fashion */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Thushi_Fashion} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Thushi Fashion</h3>
                  <p className="text-gray-600">Offering stylish and trendy fashion for every occasion.
                  </p>
                </div>
              </div>

              {/* Living More Home Nursing */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Living_More_Home_Nursing} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Living More Home Nursing</h3>
                  <p className="text-gray-600">Providing compassionate home nursing care for a better quality of life.
                  </p>
                </div>
              </div>
              
              {/* Nuga Best */}
              <div className="bg-white rounded-lg overflow-hidden shadow-lg" style={{ width: '360px', minWidth: '320px', transition: 'transform 0.3s ease-in-out', transform: isPaused ? 'none' : 'translateX(-50%)' }}>
                <a href=""><img src={Nuga_Best} alt="Sakura Bookshop" className="w-full h-64 " /></a>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Nuga Best</h3>
                  <p className="text-gray-600">Offering innovative wellness solutions to enhance your health and lifestyle.
                  </p>
                </div>
              </div>
            
            </div>
            <button onClick={scrollRight} className="text-black bg-white p-3 rounded-full focus:outline-none absolute right-0 top-1/2 transform -translate-y-1/2 shadow-md">
              &gt;
            </button>
          </div>
        </div>
      </div>


      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Footer/>
    </div>
  )
}

export default Home