import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Society from './pages/Society';
import Shops from './pages/Shops';
import Gallery from './pages/Gallery';
import Aboutus from './pages/Aboutus';
import Contatctus from './pages/Contatctus';
import News from './pages/News';


function App() {
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/society" element={<Society/>}/>
        <Route path="/shops" element={<Shops/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/aboutus" element={<Aboutus/>}/>
        <Route path="/contactus" element={<Contatctus/>}/>
        <Route path="/news" element={<News/>}/>
      </Routes>
    </Router>
     
  );
}

export default App;
