import React from 'react'
import { Link } from 'react-router-dom'
import { MdLocationOn } from "react-icons/md";
import { IoMail } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import logo from '../images/logo.png'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <div>
      <footer className="bg-gray-800 text-gray-300 py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div className="col-span-1">
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul>
                <li><Link to="/" className="hover:text-white">Home</Link></li>
                <li><Link to="/gallery">Gallery</Link></li>
                <li><Link to="/shops">Shops</Link></li>
                <li><Link to="/society" className="hover:text-white">Society</Link></li>
                <li><Link to="/contactus">Contact Us</Link></li>
                <li><Link to="/aboutus">About Us</Link></li>
              </ul>
            </div>

            {/* Contact Us */}
            <div className="col-span-1">
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <div className="flex items-center space-x-2">
                <MdLocationOn className="text-xl text-gray-400" />
                <span className="text-sm">Welikada Plaza, Rajagiriya.</span>
              </div>
              <div className="flex items-center mt-2 space-x-2">
                <IoMail className="text-xl text-gray-400" />
                <span className="text-sm">welikadaplazasociety@gmail.com</span>
              </div>
              <div className="flex items-center mt-2 space-x-2">
                <FaPhoneAlt className="text-xl text-gray-400" />
                <span className="text-sm"></span>
              </div>
            </div>

            {/* Logo and Social Icons */}
            <div className="col-span-2 sm:col-span-1 md:col-span-2 flex flex-col md:flex-row items-center justify-between">
              {/* Company Logo */}
              <div className="md:order-2 mb-4 md:mb-0" style={{marginTop:-120}}>
                <img src={logo} alt="Company Logo" className="w-80 h-auto " /> {/* Adjust width as needed */}
              </div>

              {/* Social Media Icons */}
              <div className="flex items-center space-x-4 md:order-1 mt-4 md:mt-0" style={{marginTop:-60 , marginLeft:45}}>
                <a href='https://web.facebook.com/profile.php?id=61570861211182'><FaFacebookSquare className="text-4xl text-gray-400 hover:text-white" /></a>
                <FaInstagram className="text-4xl text-gray-400 hover:text-white" />
                <FaYoutube className="text-4xl text-gray-400 hover:text-white" />
              </div>
            </div>
          </div>

          {/* Copyright Notice */}
          <hr className="mt-8 border-gray-600" />
          <div className="mt-8 text-center">
            <p>&copy; 2024 Your Company. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer